/**
 * Used by MainNavigation to make the code a bit leaner.
 * WARNING: Do not use this anywhere else.
 */

// React imports
import React from 'react'

// Remix imports
import { Link } from '@remix-run/react'

// Third party imports

// Generated imports

// App imports
import InternalLink from './InternalLink'

interface Props {
  children?: any
  className?: string
  to?: string
  prefetch?: 'intent'
  useInternalLink?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const LinkOrInternalLinkOrDiv: React.FC<Props> = ({
  children,
  to,
  prefetch,
  className,
  useInternalLink,
  onClick,
}) => {
  if (!to) {
    return <div className={className}>{children}</div>
  }

  if (useInternalLink) {
    return (
      <InternalLink
        to={to}
        prefetch={prefetch}
        className={className}
        onClick={onClick}
      >
        {children}
      </InternalLink>
    )
  }

  return (
    <Link
      to={to}
      className={
        'hover:text-brand-gold-hover hover:underline' +
        (className ? ` ${className}` : '')
      }
      onClick={onClick as any}
      prefetch={prefetch}
    >
      {children}
    </Link>
  )
}

export default LinkOrInternalLinkOrDiv
